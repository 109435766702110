import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase.js';

const SEASON = utils.getCurrentOrMostRecentSeason();
const PAST_SEASON = utils.getCurrentOrMostRecentSeason();
const UPCOMING_SEASON = utils.getUpcomingSeason();
const SEASON_START_DATE = utils.getCurrentOrMostRecentSeasonStartDate();
const UPCOMING_SEASON_START_DATE = utils.getUpcomingSeasonStartDate();

const DATABASE_SOURCE = utils.getDatabaseSource();

let DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED = new Date('May 27, 2024');

let defaultPlayer = {
  jersey_number: '',
  player_id: '0'
}

const Players = () => {
  const { playerID } = useParams();
  const [league, setLeague] = useState({
    games: [],
    gameStats: [],
    players: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);
  const [player, setPlayer] = useState({});

  useEffect(() => {
    getLeague();
  }, [])

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    league.games = league.games.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    league.gameStats = league.gameStats.filter((stat) => {
      let game = utils.getGameForID(league.games, stat.game_id)

      return game.season <= SEASON;
    });

    let teams = league.seasonTeams.filter((team) => team.season == SEASON)
    let updatedTeams = []
    for (const t of teams) {
      let teamData = league.teams.find((tm) => tm.id == t.team_id)
      let team = { ...teamData, ...t }

      updatedTeams.push(team)
    }
    league.teams = updatedTeams

    setLeague(league);
    let foundPlayer = league.players.find((player) => player.player_id == playerID)
    if (!foundPlayer) {
      let player_id = playerID
      foundPlayer = defaultPlayer;
      foundPlayer['player_id'] = player_id
      foundPlayer['first_name'] = player_id
      foundPlayer['last_name'] = ''
    }

    setPlayer(foundPlayer);
    setDataReady(true);
  }

  function displayPlayerCareer (player) {
    return (
      <>
        { displayOverallStats(player)}
      </>
    )
  }

  function displayPlayerGamesForSeason (stats, season) {
    return (
      <>
        <p className="league-title-standard-small">Season { season }</p>
        <table className="table league-table">
          <thead className="league-table-heading-hole">
            <tr>
              <th className="league-table-heading-name hole"><span>TEAM</span></th>
              <th className="league-table-heading-name hole"><span>OPP</span></th>
              <th className="league-table-heading-name hole"><span>RES</span></th>
              <th className="league-table-heading hole"><span>PTS</span></th>
              <th className="league-table-heading hole"><span>RBS</span></th>
              <th className="league-table-heading hole"><span>AST</span></th>
              <th className="league-table-heading hole"><span>BLK</span></th>
              <th className="league-table-heading hole"><span>STL</span></th>
            </tr>
          </thead>
          <thead className="league-table-heading-total">
            <tr>
              <th className="league-table-heading-small"><span>TM</span></th>
              <th className="league-table-heading-small"><span>OPP</span></th>
              <th className="league-table-heading-small"><span>RESULT</span></th>
              <th className="league-table-heading-small"><span>PTS</span></th>
              <th className="league-table-heading-small"><span>RBS</span></th>
              <th className="league-table-heading-small"><span>AST</span></th>
              <th className="league-table-heading-small"><span>BLK</span></th>
              <th className="league-table-heading-small"><span>STL</span></th>
            </tr>
          </thead>
          <tbody>
            { displayGames(stats, season) }
          </tbody>
        </table>
      </>
    )
  }

  function displayGames (stats, season) {
    let sortedStats = stats.sort(function(a,b){
      return getGameForID(a.game_id).date - getGameForID(b.game_id).date;
    });
    return sortedStats.map((stat) => displayGame(stat, season))
  }
  function displayGame (stat, season) {
    let game = getGameForID(stat.game_id);
    let team = getTeamForID(stat.team_id);
    let opposingTeam = game.home_team_id == team.id ? getTeamForID(game.away_team_id) : getTeamForID(game.home_team_id)
    let teamScore = game.home_team_id == team.id ? game.score.home : game.score.away
    let opposingTeamScore = game.home_team_id == team.id ? game.score.away : game.score.home
    let winLossChar = game.winner == team.id ? 'W' : 'L'
    let winLossWord = game.winner == team.id ? 'Win' : 'Loss'
    let dateString = `${game.date.getMonth() + 1 }/${ game.date.getDate() }/${ game.date.getFullYear().toString().substr(-2)}`

    if (stat.status == 'dnp') {
      return (
        <tr key={stat.player_id + "-team-row"}>
          <td className="league-table-data-plain max-team-abbv-width user-name"><span><a className="unstyled-link" href={"/teams/" + team.id }><span>{ team.name_abbv }</span></a></span></td>
          <td className="league-table-data-plain max-team-abbv-width user-name"><span><a className="unstyled-link" href={"/teams/" + opposingTeam.id }><span>{ opposingTeam.name_abbv }</span></a></span></td>
          <td className="league-table-data-plain max-game-result-width user-name result"><a className="unstyled-link" href={"/games/" + game.id }><span>{ winLossChar } { teamScore } - { opposingTeamScore }</span></a></td>
          <td className="league-table-data-name player-page stat-line dnp" colSpan="100%">
            <p>DNP</p>
          </td>
        </tr>
      )
    } else if (stat.status == 'forfeit') {
      return (
        <tr key={stat.player_id + "-team-row"}>
          <td className="league-table-data-plain max-team-abbv-width user-name"><span><a className="unstyled-link" href={"/teams/" + team.id }><span>{ team.name_abbv }</span></a></span></td>
          <td className="league-table-data-plain max-team-abbv-width user-name"><span><a className="unstyled-link" href={"/teams/" + opposingTeam.id }><span>{ opposingTeam.name_abbv }</span></a></span></td>
          <td className="league-table-data-plain max-game-result-width user-name result"><a className="unstyled-link" href={"/games/" + game.id }><span>{ winLossChar } { teamScore } - { opposingTeamScore }</span></a></td>
          <td className="league-table-data-name player-page stat-line forfeit" colSpan="100%">
            <p>{ winLossWord } by Forfeit</p>
          </td>
        </tr>
      )
    } else if (stat.status == 'no-stats') {
      return (
        <tr key={stat.player_id + "-team-row"}>
          <td className="league-table-data-plain max-team-abbv-width user-name"><span><a className="unstyled-link" href={"/teams/" + team.id }><span>{ team.name_abbv }</span></a></span></td>
          <td className="league-table-data-plain max-team-abbv-width user-name"><span><a className="unstyled-link" href={"/teams/" + opposingTeam.id }><span>{ opposingTeam.name_abbv }</span></a></span></td>
          <td className="league-table-data-plain max-game-result-width user-name result"><a className="unstyled-link" href={"/games/" + game.id }><span>{ winLossChar } { teamScore } - { opposingTeamScore }</span></a></td>
          <td className="league-table-data-name player-page stat-line no-stats" colSpan="100%">
            <p>No Stats</p>
          </td>
        </tr>
      )
    } else if (stat.status == 'played') {
      return (
        <tr key={stat.player_id + "-team-row"}>
          <td className="league-table-data-plain max-team-abbv-width user-name"><span><a className="unstyled-link" href={"/teams/" + team.id }><span>{ team.name_abbv }</span></a></span></td>
          <td className="league-table-data-plain max-team-abbv-width user-name"><span><a className="unstyled-link" href={"/teams/" + opposingTeam.id }><span>{ opposingTeam.name_abbv }</span></a></span></td>
          <td className="league-table-data-plain max-game-result-width user-name result"><a className="unstyled-link" href={"/games/" + game.id }><span>{ winLossChar } { teamScore } - { opposingTeamScore }</span></a></td>
          <td className="league-table-data wide"><span>{ stat.points }</span></td>
          <td className="league-table-data wide"><span>{ stat.rebounds }</span></td>
          <td className="league-table-data wide"><span>{ stat.assists }</span></td>
          <td className="league-table-data wide"><span>{ stat.blocks >= 0 ? stat.blocks : '-' }</span></td>
          <td className="league-table-data wide"><span>{ stat.steals >= 0 ? stat.steals : '-' }</span></td>
        </tr>
      )
    }
  }

  function displayPlayerGames (player) {
    let playerStats = league.gameStats.filter((stats) => stats.player_id == player.player_id)
    let seasonStats = Array.from(Array(parseInt(SEASON)), () => new Array(0))

    // Fill stats for season
    for (var i = 0; i < playerStats.length; i++) {
      let stat = playerStats[i];
      seasonStats[getGameForID(stat.game_id).season - 1].push(stat)
    }

    let playerGamesBySeason = seasonStats.reverse().map((stats, index) => {
      if (stats.length == 0) {
        return;
      }

      return displayPlayerGamesForSeason(stats, SEASON - index)
    })

    return (
      <div className="league-body">
        <div className="league-accordion-container">
          <div class="accordion accordion-flush league-accordion" id="accordionExample">
            <div class="accordion-item league-accordion-item">
              <h2 class="accordion-header league-accordion-header" id="headingOne">
                <button class="accordion-button league-accordion-button collapsed league-title-skinny-small-extended-width" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  All Games
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body league-accordion-body">
                  <div className="league-table-container schedule scroll-on-overflow">
                    { playerGamesBySeason }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function displayOverallStats (player) {
    let playerStats = league.gameStats.filter((stats) => stats.player_id == player.player_id && stats.status == 'played')
    let playerStatsWithBlocksAndSteals = playerStats.filter((stats) => getGameForID(stats.game_id).date > DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED)

    let gamesPlayed = playerStats.length
    let gamesPlayedWithBlocksAndSteals = playerStatsWithBlocksAndSteals.length
    let totalPoints = playerStats.reduce((partialPts, stats) => partialPts + stats.points, 0)
    let totalRebounds = playerStats.reduce((partialRbs, stats) => partialRbs + stats.rebounds, 0)
    let totalAssists = playerStats.reduce((partialAst, stats) => partialAst + stats.assists, 0)
    let totalBlocks = playerStats.reduce((partialAst, stats) => partialAst + (stats.blocks || 0), 0)
    let totalSteals = playerStats.reduce((partialAst, stats) => partialAst + (stats.steals || 0), 0)

    let avgPoints = (totalPoints / gamesPlayed).toFixed(1)
    let avgRebounds = (totalRebounds / gamesPlayed).toFixed(1)
    let avgAssists = (totalAssists / gamesPlayed).toFixed(1)
    let avgBlocks = (totalBlocks / gamesPlayedWithBlocksAndSteals).toFixed(1)
    let avgSteals = (totalSteals / gamesPlayedWithBlocksAndSteals).toFixed(1)

    if (gamesPlayed == 0) {
      avgPoints = 0
      avgRebounds = 0
      avgAssists = 0
    }
    if (gamesPlayedWithBlocksAndSteals == 0) {
      avgBlocks = 0
      avgSteals = 0
    }

    return (
      <>
        { displayStatsBySeason() }
        <tr key={player.player_id + "-team-row"}>
          <td className="league-table-data-plain user-name"><span></span></td>
          <td className="league-table-data-plain user-name"><span></span></td>
          <td className="league-table-data wide"><span>{ gamesPlayed }</span></td>
          <td className="league-table-data wide"><span>{ avgPoints }</span></td>
          <td className="league-table-data wide"><span>{ avgRebounds }</span></td>
          <td className="league-table-data wide"><span>{ avgAssists }</span></td>
          <td className="league-table-data wide"><span>{ avgBlocks }</span></td>
          <td className="league-table-data wide"><span>{ avgSteals }</span></td>
        </tr>
      </>
    )
  }

  function getGameForID (game_id){
    return league.games.find((game) => game.id == game_id);
  }

  function getTeamForID(team_id) {
    return league.teams.filter((team) => team.id === team_id)[0];
  }

  function displayStatsBySeason () {
    let playerStats = league.gameStats.filter((stats) => stats.player_id == player.player_id && stats.status == 'played')
    let seasonsStats = []
    for(let i = 1; i <= SEASON; i++) {
      // Need to solidify a way to discern whether a player is in the current season
      // Can look at stats in current season, but we want upcoming season to show up when no stats are present
      //
      // Option: Maybe consider removing team_id field from inactive players...
      let seasonStats = playerStats.filter((stats) => getGameForID(stats.game_id).season == i);
      let today = new Date();
      if (seasonStats.length == 0 && (i != SEASON || (today > SEASON_START_DATE && i == SEASON))) {
        continue;
      }
      let seasonStatsWithBlocksAndSteals = seasonStats.filter((stats) => getGameForID(stats.game_id).date > DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED);

      // Your teams for the season are all teams you record a stat with
      // Except for the current season, we make sure the player's current team
      // is at least listed
      let teamsForSeason = seasonStats.map((stat) => getTeamForID(stat.team_id));
      if (i == SEASON) {
        console.log(league.teams)
        let currentTeam = league.teams.find((team) => team.players.includes(player.player_id))
        teamsForSeason.push(currentTeam)
      }

      teamsForSeason = Array.from(new Set(teamsForSeason))

      let gamesPlayedThisSeason = seasonStats.length;
      let gamesPlayedThisSeasonWithBlocksAndSteals = seasonStatsWithBlocksAndSteals.length;
      let totalPoints = seasonStats.reduce((partialPts, stats) => partialPts + stats.points, 0)
      let totalRebounds = seasonStats.reduce((partialRbs, stats) => partialRbs + stats.rebounds, 0)
      let totalAssists = seasonStats.reduce((partialAst, stats) => partialAst + stats.assists, 0)
      let totalBlocks = seasonStats.reduce((partialAst, stats) => partialAst + (stats.blocks || 0), 0)
      let totalSteals = seasonStats.reduce((partialAst, stats) => partialAst + (stats.steals || 0), 0)

      let avgPoints = (totalPoints / gamesPlayedThisSeason).toFixed(1)
      let avgRebounds = (totalRebounds / gamesPlayedThisSeason).toFixed(1)
      let avgAssists = (totalAssists / gamesPlayedThisSeason).toFixed(1)
      let avgBlocks = (totalBlocks / gamesPlayedThisSeasonWithBlocksAndSteals).toFixed(1)
      let avgSteals = (totalSteals / gamesPlayedThisSeasonWithBlocksAndSteals).toFixed(1)

      if (gamesPlayedThisSeason == 0) {
        avgPoints = 0
        avgRebounds = 0
        avgAssists = 0
      }
      if (gamesPlayedThisSeasonWithBlocksAndSteals == 0) {
        avgBlocks = 0
        avgSteals = 0
      }

      seasonsStats.push(
        <>
          <tr key={player.player_id + "-team-row"}>
            <td className="league-table-data-plain user-name"><span>{ i }</span></td>
            <td className="league-table-data-plain user-name">
              {
                teamsForSeason.map((team, team_number) => (
                  <>
                    <a className="unstyled-link" href={"/teams/" + team.id }><span>{ team.name_abbv }</span></a>{ team_number != teamsForSeason.length - 1 ? ', ' : ''}
                  </>
                ))
              }
            </td>
            <td className="league-table-data wide"><span>{ gamesPlayedThisSeason }</span></td>
            <td className="league-table-data wide"><span>{ avgPoints }</span></td>
            <td className="league-table-data wide"><span>{ avgRebounds }</span></td>
            <td className="league-table-data wide"><span>{ avgAssists }</span></td>
            <td className="league-table-data wide"><span>{ avgBlocks }</span></td>
            <td className="league-table-data wide"><span>{ avgSteals }</span></td>
          </tr>
        </>
      )
    }

    return seasonsStats;
  }

  //   return (
      // <tr key={player.player_id + "-team-row"}>
      //   <td className="league-table-data wide"><span>1</span></td>
      //   <td className="league-table-data wide"><span>team</span></td>
      //   <td className="league-table-data wide"><span>gp</span></td>
      //   <td className="league-table-data wide"><span>pts</span></td>
      //   <td className="league-table-data wide"><span>rbs</span></td>
      //   <td className="league-table-data wide"><span>ast</span></td>
      // </tr>
  //   )
  // }

  function displayJerseyNumber (player) {
    if (player.jersey_number == '') {
      return ``
    }
    return `#${player.jersey_number}`
  }
  function displayPlayerHeight (player) {
    const height = player.height
    return `${height.feet}' ${height.inches}"`
  }


  function displayPlayerName (player) {
    return `${player.first_name} ${player.last_name}`
  }

  if (dataReady == false) {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Players">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">
          <div className="league-header">
            <div className="league-header-top">
              <p className="league-title-standard player-name-title">{ displayPlayerName(player) }</p>
            </div>
          </div>
          <div className="league-body">
            <div className="league-table-container schedule scroll-on-overflow">
              <table className="table league-table">
                <thead className="league-table-heading-hole">
                  <tr>
                    <th className="league-table-heading hole"><span>SEASON</span></th>
                    <th className="league-table-heading-name hole"><span>TEAM</span></th>
                    <th className="league-table-heading hole"><span>GP</span></th>
                    <th className="league-table-heading hole"><span>PTS</span></th>
                    <th className="league-table-heading hole"><span>RBS</span></th>
                    <th className="league-table-heading hole"><span>AST</span></th>
                    <th className="league-table-heading hole"><span>BLK</span></th>
                    <th className="league-table-heading hole"><span>STL</span></th>
                  </tr>
                </thead>
                <thead className="league-table-heading-total">
                  <tr>
                    <th className="league-table-heading-small"><span>SZN</span></th>
                    <th className="league-table-heading-small"><span>TM</span></th>
                    <th className="league-table-heading-small"><span>GP</span></th>
                    <th className="league-table-heading-small"><span>PTS</span></th>
                    <th className="league-table-heading-small"><span>RBS</span></th>
                    <th className="league-table-heading-small"><span>AST</span></th>
                    <th className="league-table-heading-small"><span>BLK</span></th>
                    <th className="league-table-heading-small"><span>STL</span></th>
                  </tr>
                </thead>
                <tbody>
                  { displayPlayerCareer(player) }
                </tbody>
              </table>
            </div>
          </div>
          { displayPlayerGames(player) }
        </div>
        <Footer/>
      </div>
    );
  }
}

export default Players;

import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation';
import * as utils from '../common/Utils.js'
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase';

const SEASON = utils.getCurrentOrMostRecentSeason();
const DATABASE_SOURCE = utils.getDatabaseSource();

let DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED = new Date('May 27, 2024');
let SAVE_BLOCKS_AND_STEALS = true;

const Admin = () => {
  const [currentUser, setCurrentUser] = useState({})
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setCurrentUser(currentUser)

        if (utils.isAdminEmail(currentUser.email)) {
          setDataReady(true)
        } else {
          window.location.href = "/";
        }
      } else {
        setCurrentUser(null)
        window.location.href = "/login";
      }

    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, [])

  if (dataReady == false || currentUser == null) {
    return (
      <div className="Account">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  } else {
    return (
      <div>
        <NavBarMini alwaysShown={true} />

        <div className="league-content">

          <div className="league-body">
            <p className="league-title-standard-secondary text-align-center">Choose an Admin Action</p>
            <div className="league-table-container schedule large-item-buttons">
              <div className="large-item-button" onClick={() => { window.location.href = '/admin/games'}}>
                <h2>Game</h2>
                <p>Enter stats for game</p>
              </div>
              <div className="large-item-button" onClick={() => { window.location.href = '/admin/teams' }}>
                <h2>Team</h2>
                <p>Edit team data</p>
              </div>
              <div className="large-item-button" onClick={() => { window.location.href = '/admin/seasons' }}>
                <h2>Season</h2>
                <p>Edit season data</p>
              </div>
            </div>
          </div>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default Admin;

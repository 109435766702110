import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase.js';

const SEASON = utils.getCurrentOrMostRecentSeason();
const UPCOMING_SEASON = utils.getUpcomingSeason();
const DATABASE_SOURCE = utils.getDatabaseSource();

let DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED = new Date('May 27, 2024');
let SAVE_BLOCKS_AND_STEALS = true;

const AdminSeasons = () => {
  const { season } = useParams();
  const [league, setLeague] = useState({
    games: [],
    gameStats: [],
    players: [],
    seasonTeams: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);
  const [currentUser, setCurrentUser] = useState({})
  const [team, setTeam] = useState({})

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setCurrentUser(currentUser)

        if (utils.isAdminEmail(currentUser.email)) {
          getLeague()
        } else {
          window.location.href = "/";
        }
      } else {
        setCurrentUser(null)
        window.location.href = "/login";
      }
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, [])

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    let sortedGames = league.games.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });
    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    setLeague(league);
    setDataReady(true);
  }

  function displaySeasons () {
    const seasonsDisplay =  Array.from({ length: Number(UPCOMING_SEASON) }).map((_, szn) => displaySeason(szn + 1))

    return seasonsDisplay
  }

  function displaySeason (season) {
    return (
      <div className="large-item-button" onClick={() => { window.location.href = `/admin/seasons/${season}`}}>
        <h2>Season { season }</h2>
      </div>
    )
  }

  function displayEditSeason () {
    let completed = season < UPCOMING_SEASON
    let seasonStatus = completed ? 'Completed' : 'Upcoming'
    let teamIDsForSeason = league.seasonTeams.filter((seasonTeam) => seasonTeam.season == season).map((seasonTeam) => seasonTeam.team_id)

    return (
      <>
        <form>
          <div className="form-row">
            <div className="form-group">
              <label for="inputSeasonName">Season Name</label>
              <input type="text" className="form-control" id="inputSeasonName" defaultValue={ season } disabled></input>
            </div>
            <div className="form-group">
              <label for="inputSeasonStatus">Status</label>
              <input type="text" className="form-control" id="inputSeasonStatus" defaultValue={ seasonStatus } disabled></input>
            </div>
          </div>
          <div className="form-group">
            <label for="teamsForSeason">Teams for Season</label>
            {
              league.teams.map((tm) => {
                return (
                  <div className='league-admin-season-team'>
                    <h3><a className='league-link' href={ "/admin/seasons/" + season + "/teams/" + tm.id }>{ tm.name }</a></h3>
                    <input className="form-check-input big-checkbox" type="checkbox" value="" data-team-id={ tm.id } id={ "teamSeasonCheckbox-" + tm.id } defaultChecked={ teamIDsForSeason.includes(tm.id) } disabled={ completed }/>
                  </div>
                )
              })
            }
          </div>
          <button className="btn btn-dark" type="button" onClick={ saveSeason }>Save Season</button>
        </form>
      </>
    )
  }

  function findOrCreateSeasonTeam (team_id) {
    let team = utils.getTeamForID(league.teams, team_id)

    let existingSeasonTeam = league.seasonTeams.find((seasonTeam) => seasonTeam.team_id == team_id && seasonTeam.season == season)

    if (existingSeasonTeam) {
      return existingSeasonTeam
    }

    let seasonTeam = {
      captain: '',
      players: [],
      season: season,
      seed: '',
      team_id: team_id
    }

    return seasonTeam
  }

  function saveSeason () {
    let checkboxes = league.teams.map((team) => document.getElementById("teamSeasonCheckbox-" + team.id))
    let seasonsTeamIDs = checkboxes.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.dataset.teamId)

    let seasonTeams = seasonsTeamIDs.map((team_id) => findOrCreateSeasonTeam(team_id))

    let otherSeasonTeams = league.seasonTeams.filter((seasonTeam) => seasonTeam.season != season)
    let newSeasonTeams = [...otherSeasonTeams, ...seasonTeams]

    league.seasonTeams = newSeasonTeams
    saveLeague()
  }

  const saveLeague = async () => {
    console.log('saving league')
    await setDoc(doc(db, "league", DATABASE_SOURCE), league);
  }

  if (dataReady == false || !team || team == {}) {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">
          <div className="league-body">
            <p className='league-game-back-button'><a className='league-link' href="/admin"><b>← Back to Admin Home</b></a></p>
            { season ? <p className='league-game-back-button'><a className='league-link' href="/admin/seasons"><b>← Back to Admin &gt; Seasons</b></a></p> : '' }

            <p className="league-title-standard-secondary text-align-center">Edit Season</p>
            <div className="league-table-container schedule large-item-buttons">
            {
                season ?
                displayEditSeason()
                :
                displaySeasons()
              }
            </div>
          </div>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default AdminSeasons;
